import { Constants } from '@/app/lib/constants';
import type { ToolCallRequest } from '@/app/lib/dtos/ToolCallRequest';
import type { ToolCallResponse } from '@/app/lib/dtos/ToolCallResponse';
import type { MessageContentType } from '@/app/lib/entities/ChatMessage.entity';
import type { User, UserType } from '@/app/lib/models/user';

export interface ResultFailure<T> {
	ok: false;
	value: unknown;
	error?: T;
}

export type Result<T = unknown, E = unknown> = { ok: true; value: T } | ResultFailure<E>;

export const AllAppTypes = [
	'web application',
	'windows desktop application',
	'linux desktop application',
	'osx desktop application',
	'android mobile application',
	'iphone mobile application',
	'windows console application',
	'linux console application',
	'osx console application',
	'api service',
] as const;
type AllAppTypesTuple = typeof AllAppTypes;
export type AppType = AllAppTypesTuple[number];

export const AllToolTypes = [
	'build-full-production',
	'build-prototype',
	'get-build-status',
	'stop-build',
	'resume-build',
	'create-all-designs',
	'ask-designer',
	'ask-engineer',
	'ask-architect',
	'ask-devops',
	'ask-tester',
	'ask-project-manager',
	'create-specific-design',
	'edit-design',
	'execute-command-line',
	'overwrite-file',
	'create-new-file',
	'google-search',
	'get-web-page',
	'get-web-page-with-detail',
	'ask-user-in-build',
	'web-page-interact-with-detail',
	'restart-web-app',
] as const;
type AllToolTypesTuple = typeof AllToolTypes;
export type ToolType = AllToolTypesTuple[number];

export const AskTools: ToolType[] = [
	'ask-designer',
	'ask-engineer',
	'ask-architect',
	'ask-devops',
	'ask-tester',
	'ask-project-manager',
];

export enum ConnectivityState {
	YES = 'YES',
	NO = 'NO',
	RESTART = 'RESTART',
	STARTED = 'STARTED',
}

export enum FunctionalityState {
	YES = 'YES',
	NO = 'NO',
}

const extractGeneral = (
	message: string,
	start: string,
	end: string,
	shouldRemoveFirstLine: boolean,
	shouldCaptureTags: boolean,
	isGreedy: boolean,
) => {
	try {
		let firstBracketIndex = (message || '').indexOf(start);
		if (firstBracketIndex >= 0) {
			let lastBracketIndex = isGreedy
				? (message || '').lastIndexOf(end)
				: (message || '').indexOf(end, firstBracketIndex + start.length);
			if (lastBracketIndex > firstBracketIndex) {
				if (shouldCaptureTags) {
					lastBracketIndex += end.length;
				} else {
					firstBracketIndex += start.length;
				}

				let result = message?.substring(firstBracketIndex, lastBracketIndex);

				if (shouldRemoveFirstLine) {
					const newLineIndex = result.indexOf('\n');
					if (newLineIndex >= 0) {
						result = result.substring(newLineIndex + 1);
					}
				}

				return (result ?? '').trim();
			}
		}
	} catch (error) {
		console.error(error);
	}
	return undefined;
};

// eslint-disable-next-line
export class Shared {
	static VALID_UI_MESSAGE_TYPES: MessageContentType[] = ['image', 'text', 'tool-request', 'tool-result', 'file'];

	static LimitToTheLast<T>(array: T[], limit: number): T[] {
		let output = array;

		if (output.length > limit) {
			output = output.slice(Math.max(0, output.length - limit));
		}

		return output;
	}

	static IsQuestionBody(body: unknown): boolean {
		const messageContent = String(body);
		const questionStart = messageContent.includes('\n-----\nQUESTION STARTS');
		const questionEnd = messageContent.includes('QUESTION ENDS\n-----');

		return questionStart && questionEnd;
	}

	static LimitByPredicate<T>(threads: T[], limit: number, predicate: (arg0: T) => string | number | symbol): T[] {
		const output: T[] = [];
		const map: Record<string | number | symbol, number> = {};
		const len = threads.length;
		for (let i = len - 1; i >= 0; i -= 1) {
			const t = threads[i];
			const k = predicate(t);
			if (k) {
				if (!map[k]) {
					map[k] = 0;
				}
				if (map[k] < limit) {
					map[k] += 1;
					output.unshift(t);
				}
			}
		}

		return output;
	}

	static ExtractHTMLTags(message: string, tag: string) {
		const matches: string[] = [];
		let unparsedData = message;
		let command = Shared.ExtractHTMLTag(unparsedData, tag);
		while (command) {
			if (command) {
				matches.push(command);
			}
			unparsedData = unparsedData.substring(unparsedData.indexOf(command) + command.length);
			command = Shared.ExtractHTMLTag(unparsedData, tag);
		}
		return matches;
	}

	static ExtractHTMLTag(message: string, tag: string) {
		return extractGeneral(message, `<${tag}>`, `</${tag}>`, false, true, false);
	}

	static RemoveHTMLTags(message: string, tag: string) {
		let unparsedData = message;
		const matches = Shared.ExtractHTMLTags(message, tag);
		if (matches?.length) {
			for (const match of matches) {
				unparsedData = unparsedData.replace(match, '').trim();
			}
		}
		return unparsedData;
	}

	static ExtractJSON(message: string): unknown {
		try {
			const matched = extractGeneral(message, '{', '}', false, true, true);
			if (matched) {
				return JSON.parse(matched) as unknown;
			}
		} catch (error) {
			console.error(error);
		}
		return undefined;
	}

	static ExtractJSONArray<T>(message: string) {
		try {
			const matched = extractGeneral(message, '[', ']', false, true, false);
			if (matched) {
				return JSON.parse(matched) as T[];
			}
		} catch (error) {
			console.error(error);
		}
		return [] as T[];
	}

	static ExtractCommands(message: string, markdownType = 'bash') {
		const matches: string[] = [];
		let unparsedData = message;
		let command = extractGeneral(unparsedData, '```' + markdownType, '```', true, false, false);
		while (command) {
			if (command) {
				matches.push(command);
			}
			unparsedData = unparsedData.substring(unparsedData.indexOf(command) + command.length);
			command = extractGeneral(unparsedData, '```' + markdownType, '```', true, false, false);
		}
		return matches;
	}

	static ExtractMarkdown(message: string) {
		if (message?.includes('```')) {
			return extractGeneral(message, '```', '```', true, false, true);
		}
		return extractGeneral(message, '`', '`', false, false, false);
	}

	static ExtractMarkdownFromResult(message: Result<string, string>) {
		if (message.ok) {
			return Shared.ExtractMarkdown(message.value);
		}
		return undefined;
	}

	static ErrorToString(error: unknown, messageOnly = false): string {
		if (error instanceof Error) {
			if (messageOnly) {
				return (error.message ?? '').trim();
			}
			return `${error.name} - ${(error.message ?? '').trim()} - ${error.stack ?? ''}`;
		}
		return String(error);
	}

	static CancelledError() {
		return new Error(Constants.CANCELLATION_MESSAGE);
	}

	static Cancelled(): ResultFailure<string> {
		return Shared.Fail(Constants.CANCELLATION_MESSAGE);
	}

	static IsCancellationMessage(message: string) {
		return message == Constants.CANCELLATION_MESSAGE;
	}

	static MergeArrays<T>(array1: T[], array2: T[], sameTest: (a: T, b: T) => boolean): T[] {
		const output: T[] = [];
		const exists = (el: T) => {
			return output.find((value: T) => sameTest(value, el)) != undefined;
		};

		for (const val1 of array1) {
			if (!exists(val1)) {
				output.push(val1);
			}
		}

		for (const val2 of array2) {
			if (!exists(val2)) {
				output.push(val2);
			}
		}

		return output;
	}

	static Fail<T>(error?: T): ResultFailure<T> {
		const result: ResultFailure<T> = { ok: false, value: undefined };

		if (error != undefined) {
			result.error = error;
		}

		return result;
	}

	static Success<T, E = unknown>(value: T): Result<T, E> {
		const result: Result<T, E> = {
			ok: true,
			value,
		};

		return result;
	}

	static RandomString(length = 20) {
		return [...Array<number>(length)].map(() => Math.random().toString(36)[2]).join('');
	}

	static Wait(ms: number): Promise<void> {
		return new Promise((resolve) => setTimeout(resolve, ms));
	}

	static IgnoreList = ['conversation.md', 'specification.md', 'architecture.md', '.next', '.git', 'node_modules'];

	static IncludeList = [
		'.md',
		'.py',
		'.txt',
		'.html',
		'.html',
		'.htm',
		'.js',
		'.css',
		'.ts',
		'.json',
		'.yml',
		'.yaml',
		'.jsx',
		'.tsx',
		'.conf',
		'.dockerignore',
		'.gitignore',
	];

	static Hash(input: string): number {
		let hash = 0;

		if (input?.length) {
			for (let i = 0; i < input.length; i++) {
				const char = input.charCodeAt(i);
				hash = (hash << 5) - hash + char;
				hash = hash & hash;
			}
		}

		return hash;
	}

	static IsString(candidate: unknown, errorMessage = 'candidate is not a string'): asserts candidate is string {
		if ((candidate ?? 0).constructor.name != 'String') {
			throw new Error(errorMessage);
		}
	}

	static IsArray<T>(candidate: unknown, errorMessage = 'candidate is not an Array'): asserts candidate is T[] {
		if ((candidate ?? 0).constructor.name != 'Array') {
			throw new Error(errorMessage);
		}
	}

	static IsToolCallRequest(
		candidate: unknown,
		errorMessage = 'candidate is not a ToolCallRequest',
	): asserts candidate is ToolCallRequest {
		if (!(candidate as ToolCallRequest).arguments) {
			throw new Error(errorMessage);
		}
	}

	static IsToolCallResponse(
		candidate: unknown,
		errorMessage = 'candidate is not a ToolCallResponse',
	): asserts candidate is ToolCallResponse {
		const response = (candidate as ToolCallResponse).response;
		if (!response && response !== '') {
			throw new Error(errorMessage);
		}
	}

	static Users: Record<UserType, User> = {
		user: {
			id: 'user',
			displayName: 'you',
		},
		projectManager: {
			id: 'projectManager',
			displayName: 'Natalya - Project Manager',
		},
		architect: {
			id: 'architect',
			displayName: 'Peter - Architect',
		},
		designer: {
			id: 'designer',
			displayName: 'Sara - Designer',
		},
		engineer: {
			id: 'engineer',
			displayName: 'Adam - Engineer',
		},
		tester: {
			id: 'tester',
			displayName: 'Noah - Tester',
		},
		devops: {
			id: 'devops',
			displayName: 'Emma - DevOps',
		},
	};

	static GetMimeFromFileName(path: string | undefined) {
		const extDict: Record<string, string> = {
			'.bat': 'bat',
			'.cpp': 'cpp',
			'.cs': 'csharp',
			'.css': 'css',
			'.dockerfile': 'dockerfile',
			'.fs': 'fsharp',
			'.go': 'go',
			'.graphql': 'graphql',
			'.htm': 'html',
			'.html': 'html',
			'.ini': 'ini',
			'.java': 'java',
			'.js': 'javascript',
			'.jsx': 'javascript',
			'.json': 'json',
			'.less': 'less',
			'.lua': 'lua',
			'.md': 'markdown',
			'.php': 'php',
			'.py': 'python',
			'.rb': 'ruby',
			'.ruby': 'ruby',
			'.rs': 'rust',
			'.rust': 'rust',
			'.scss': 'scss',
			'.sh': 'shell',
			'.sql': 'sql',
			'.ts': 'typescript',
			'.tsx': 'typescript',
			'.vb': 'vb',
			'.xml': 'xml',
			'.yml': 'yaml',
			'.yaml': 'yaml',
		};

		const extIndex = path?.lastIndexOf('.');
		if (path && extIndex && extIndex > 0) {
			const ext = path.toLocaleLowerCase().substring(extIndex);
			if (ext && extDict[ext]) {
				return extDict[ext];
			}
		}

		return undefined;
	}

	static TakeSummary(input: string, charLimit: number) {
		const EXTRA_LENGTH = 5;
		if (input.length > charLimit - EXTRA_LENGTH) {
			const halfLimit = Math.floor((charLimit - EXTRA_LENGTH) / 2);
			return `${Shared.TakeFirstCharacters(input, halfLimit)}
...
${Shared.TakeLastCharacters(input, halfLimit)}`;
		}
		return input;
	}

	static TakeLastCharacters(input: string, charLimit: number) {
		if (input.length > charLimit) {
			return input.substring(Math.max(0, input.length - charLimit));
		}
		return input;
	}

	static TakeFirstCharacters(input: string, charLimit: number) {
		if (input.length > charLimit) {
			return input.substring(0, charLimit);
		}
		return input;
	}

	static GetMarkdownSyntaxFromFileName(path: string) {
		const extDict: Record<string, string[]> = {
			abap: ['.abap'],
			ada: ['.adb', '.ads', '.ada'],
			ahk: ['.ahk', '.ahkl'],
			apacheconf: ['.htaccess', 'apache.conf', 'apache2.conf'],
			applescript: ['.applescript'],
			as: ['.as'],
			as3: ['.as'],
			asy: ['.asy'],
			bash: ['.sh', '.ksh', '.bash', '.ebuild', '.eclass'],
			bat: ['.bat', '.cmd'],
			befunge: ['.befunge'],
			blitzmax: ['.bmx'],
			boo: ['.boo'],
			brainfuck: ['.bf', '.b'],
			c: ['.c', '.h'],
			cfm: ['.cfm', '.cfml', '.cfc'],
			cheetah: ['.tmpl', '.spt'],
			cl: ['.cl', '.lisp', '.el'],
			clojure: ['.clj', '.cljs'],
			cmake: ['.cmake', 'CMakeLists.txt'],
			coffeescript: ['.coffee'],
			console: ['.sh-session'],
			control: ['control'],
			cpp: ['.cpp', '.hpp', '.c++', '.h++', '.cc', '.hh', '.cxx', '.hxx', '.pde'],
			csharp: ['.cs'],
			css: ['.css'],
			cython: ['.pyx', '.pxd', '.pxi'],
			d: ['.d', '.di'],
			delphi: ['.pas'],
			diff: ['.diff', '.patch'],
			dpatch: ['.dpatch', '.darcspatch'],
			duel: ['.duel', '.jbst'],
			dylan: ['.dylan', '.dyl'],
			erb: ['.erb'],
			erl: ['.erl-sh'],
			erlang: ['.erl', '.hrl'],
			evoque: ['.evoque'],
			factor: ['.factor'],
			felix: ['.flx', '.flxh'],
			fortran: ['.f', '.f90'],
			gas: ['.s', '.S'],
			genshi: ['.kid'],
			gitignore: ['.gitignore'],
			glsl: ['.vert', '.frag', '.geo'],
			gnuplot: ['.plot', '.plt'],
			go: ['.go'],
			groff: ['.(1234567)', '.man'],
			haml: ['.haml'],
			haskell: ['.hs'],
			html: ['.html', '.htm', '.xhtml', '.xslt'],
			hx: ['.hx'],
			hybris: ['.hy', '.hyb'],
			ini: ['.ini', '.cfg'],
			io: ['.io'],
			ioke: ['.ik'],
			irc: ['.weechatlog'],
			jade: ['.jade'],
			java: ['.java'],
			js: ['.js', '.jsx'],
			json: ['.json'],
			jsp: ['.jsp'],
			lhs: ['.lhs'],
			llvm: ['.ll'],
			logtalk: ['.lgt'],
			lua: ['.lua', '.wlua'],
			make: ['.mak', 'Makefile', 'makefile', 'Makefile.', 'GNUmakefile'],
			mako: ['.mao'],
			maql: ['.maql'],
			mason: ['.mhtml', '.mc', '.mi', 'autohandler', 'dhandler'],
			markdown: ['.md'],
			modelica: ['.mo'],
			modula2: ['.def', '.mod'],
			moocode: ['.moo'],
			mupad: ['.mu'],
			mxml: ['.mxml'],
			myghty: ['.myt', 'autodelegate'],
			nasm: ['.asm', '.ASM'],
			newspeak: ['.ns2'],
			objdump: ['.objdump'],
			objectivec: ['.m'],
			objectivej: ['.j'],
			ocaml: ['.ml', '.mli', '.mll', '.mly'],
			ooc: ['.ooc'],
			perl: ['.pl', '.pm'],
			php: ['.php', '.php(345)'],
			postscript: ['.ps', '.eps'],
			pot: ['.pot', '.po'],
			pov: ['.pov', '.inc'],
			prolog: ['.prolog', '.pro', '.pl'],
			properties: ['.properties'],
			protobuf: ['.proto'],
			py3tb: ['.py3tb'],
			pytb: ['.pytb'],
			python: ['.py', '.pyw', '.sc', 'SConstruct', 'SConscript', '.tac'],
			r: ['.R'],
			rb: ['.rb', '.rbw', 'Rakefile', '.rake', '.gemspec', '.rbx', '.duby'],
			rconsole: ['.Rout'],
			rebol: ['.r', '.r3'],
			redcode: ['.cw'],
			rhtml: ['.rhtml'],
			rst: ['.rst', '.rest'],
			sass: ['.sass'],
			scala: ['.scala'],
			scaml: ['.scaml'],
			scheme: ['.scm'],
			scss: ['.scss'],
			smalltalk: ['.st'],
			smarty: ['.tpl'],
			sourceslist: ['sources.list'],
			splus: ['.S', '.R'],
			sql: ['.sql'],
			sqlite3: ['.sqlite3-console'],
			squidconf: ['squid.conf'],
			ssp: ['.ssp'],
			tcl: ['.tcl'],
			tcsh: ['.tcsh', '.csh'],
			tex: ['.tex', '.aux', '.toc'],
			text: ['.txt'],
			typescript: ['.ts'],
			v: ['.v', '.sv'],
			vala: ['.vala', '.vapi'],
			vbnet: ['.vb', '.bas'],
			velocity: ['.vm', '.fhtml'],
			vim: ['.vim', '.vimrc'],
			xml: ['.xml', '.xsl', '.rss', '.xslt', '.xsd', '.wsdl'],
			xquery: ['.xqy', '.xquery'],
			xslt: ['.xsl', '.xslt'],
			yaml: ['.yaml', '.yml'],
		};

		if (path) {
			const lower = path.toLocaleLowerCase();
			for (const key in extDict) {
				if (Object.prototype.hasOwnProperty.call(extDict, key)) {
					const element = extDict[key];
					for (const ext of element) {
						if (lower.endsWith(ext)) {
							return key;
						}
					}
				}
			}
		}
		return undefined;
	}

	static DetermineTargetUser(toolType: ToolType, defaultUser: UserType = 'projectManager'): UserType {
		if (toolType == 'ask-designer') {
			return 'designer';
		} else if (toolType == 'ask-engineer') {
			return 'engineer';
		} else if (toolType == 'ask-architect') {
			return 'architect';
		} else if (toolType == 'ask-devops') {
			return 'devops';
		} else if (toolType == 'ask-tester') {
			return 'tester';
		} else if (toolType == 'ask-project-manager') {
			return 'projectManager';
		}
		return defaultUser;
	}

	static IsAllowedFile(fullPath: string, excludeImages = false) {
		const checkPath = (fullPath || '').toLowerCase();
		let isValid = !Constants.DISALLOWED_FILES.find((d) => checkPath.includes(d));

		if (isValid && excludeImages) {
			isValid = !Constants.DISALLOWED_LLM_GENERATED_ENDINGS.find((d) => checkPath.endsWith(d));
		}

		return isValid;
	}

	static async Retry<T>(fn: () => Promise<Result<T, string>>, retries = 3): Promise<Result<T, string>> {
		let result: Result<T, string> = Shared.Fail();
		let count = 0;

		while (!result.ok && count < retries) {
			result = await fn();
			count += 1;
		}

		return result;
	}
}
